var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full py-5 px-5"},[_c('div',{staticClass:"flex justify-between items-center flex-row w-full"},[_c('div',{staticClass:"text-center flex justify-center items-between w-full",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"flex justify-center w-full items-center flex-col",staticStyle:{"min-height":"50vh"}},[(_vm.identifier.processStatus === '1')?_c('div',{staticClass:"flex justify-between items-center flex-col w-full",staticStyle:{"min-height":"45vh"}},[_c('span',{staticClass:"text-2xl font-bold my-2"},[_vm._v("Tutup Operasional Kantor")]),_c('span',{staticClass:"text-lg my-2"},[_vm._v(_vm._s(_vm.dataForm.officeName)+" ")]),_c('span',{staticClass:"text-lg my-2"},[_vm._v(_vm._s(_vm.dateToMonthText(_vm.dataForm.date)))]),_vm._m(0),_c('div',{staticClass:"w-9/12 flex justify-between flex-col p-2 border-log-operational_process_closing"},[_c('b-table',{staticClass:"w-full",attrs:{"mobile-cards":false,"data":_vm.table.data.officeTransaction,"striped":"","narrowed":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.officeTransaction.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(
                      _vm.property.listElement.officeTransaction.downloading ===
                        false
                    )?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.officeTransaction.message ? _vm.property.listElement.officeTransaction.message : _vm.table.data.officeTransaction.length === 0 ? "LOG NOT FOUND" : _vm.property.listElement.officeTransaction.message)+" ")]):_vm._e()],1)]},proxy:true}],null,false,3485689586)},[_c('b-table-column',{attrs:{"centered":"","field":"unauthorizedName","label":"Nama"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                    var column = ref.column;
return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.unauthorizedName ? props.row.unauthorizedName : "-"))])]}}],null,false,3819332629)}),_c('b-table-column',{attrs:{"centered":"","field":"total","label":"Total"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                    var column = ref.column;
return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.total ? props.row.total : "-"))])]}}],null,false,1088360917)})],1),_c('b-button',{attrs:{"expanded":""},on:{"click":_vm.getOfficeTransactionOperationalProccess}},[_vm._v("Refresh")])],1),_c('div',{staticClass:"flex justify-center w-full mt-5"},[_c('b-button',{staticClass:"button-theme-confirm",attrs:{"loading":_vm.property.animation.putOperationalProcess.isLoading,"type":"is-success","disabled":_vm.property.animation.putOperationalProcess.isLoading === true},on:{"click":_vm.clickButtonClosingOperational}},[_vm._v(" Tutup Operasional Kantor")])],1)]):_vm._e(),(_vm.identifier.processStatus === '0')?_c('div',{staticClass:"flex justify-between items-center flex-col w-full",staticStyle:{"min-height":"45vh"}},[_c('span',{staticClass:"text-2xl font-bold my-2"},[_vm._v("Tutup Operasional Kantor")]),_c('span',{staticClass:"text-lg my-2"},[_vm._v(_vm._s(_vm.dataForm.officeName)+" ")]),_c('span',{staticClass:"text-lg my-2"},[_vm._v(_vm._s(_vm.dateToMonthText(_vm.dataForm.date)))]),_c('div',{staticClass:"w-9/12 flex justify-between flex-col p-2 border-log-operational_process_closing"},[_c('b-table',{staticClass:"w-full",attrs:{"mobile-cards":false,"data":_vm.table.data.officeTransaction,"striped":"","bordered":"","narrowed":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center py-2"},[(_vm.property.listElement.officeTransaction.downloading)?_c('cbs-table-progres',{attrs:{"size":"10px"}}):_vm._e(),(
                      _vm.property.listElement.officeTransaction.downloading ===
                        false
                    )?_c('span',{staticClass:"text-base"},[_vm._v(" "+_vm._s(_vm.property.listElement.officeTransaction.message ? _vm.property.listElement.officeTransaction.message : _vm.table.data.officeTransaction.length === 0 ? "LOG NOT FOUND" : _vm.property.listElement.officeTransaction.message)+" ")]):_vm._e()],1)]},proxy:true}],null,false,3485689586)},[_c('b-table-column',{attrs:{"centered":"","field":"unauthorizedName","label":"Nama"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                    var column = ref.column;
return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.unauthorizedName ? props.row.unauthorizedName : "-"))])]}}],null,false,3819332629)}),_c('b-table-column',{attrs:{"centered":"","field":"total","label":"Total"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
                    var column = ref.column;
return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(props.row.total ? props.row.total : "-"))])]}}],null,false,1088360917)})],1),_c('b-button',{attrs:{"expanded":""},on:{"click":_vm.getOfficeTransactionOperationalProccess}},[_vm._v("Refresh")])],1),_c('b-notification',{attrs:{"type":"is-danger","closable":false,"has-icon":"","aria-close-label":"Close notification","role":"alert"}},[_c('span',{staticClass:"text-xl"},[_vm._v("Cabang "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.dataForm.officeName))]),_vm._v(". Kondisi Saat Ini : Sudah Tertutup")])])],1):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-notice-operational_process_closing my-2"},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-lg"},[_vm._v("Pastikan Bahwa")]),_c('span',{staticClass:"text-base"},[_vm._v("1. Semua Transaksi Sudah Di Entry")]),_c('span',{staticClass:"text-base"},[_vm._v("2. Semua Transaksi Sudah Di Authorisasi")]),_c('span',{staticClass:"text-base"},[_vm._v("3. Balancing Kas dan Cetak Laporan Transaksi Harian")])])])}]

export { render, staticRenderFns }